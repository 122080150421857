var header = document.getElementById("header-scroll");

if ( header ) {

    var doc = document.documentElement;
    var w = window;

    var curScroll;
    var prevScroll = w.scrollY || doc.scrollTop;
    var curDirection = 0;
    var prevDirection = 0;

    var header = document.getElementById("header-scroll");
    var toggled;
    var threshold = 100;

    var checkScroll = function () {
        curScroll = w.scrollY || doc.scrollTop;
        if (curScroll > prevScroll) {
            // scrolled down
            curDirection = 2;
        } else {
            //scrolled up
            curDirection = 1;
        }

        if (curDirection !== prevDirection) {
            toggled = toggleHeader();
        }

        prevScroll = curScroll;
        if (toggled) {
            prevDirection = curDirection;
        }
    };

    var toggleHeader = function () {
        toggled = true;

        if (curDirection === 2 && curScroll > threshold) {
            header.classList.add("hide");
            header.classList.add("fixed");
        } else {
            toggled = false;
        }

        if (curDirection === 1) {
            header.classList.remove("hide");
            if (curScroll < threshold) header.classList.remove("fixed");
        } else {
            toggled = false;
        }

        return toggled;
    };

    window.addEventListener("scroll", checkScroll);




    const scrollBtn 		= document.querySelector(".js-scroll");

    if (scrollBtn) {
    scrollBtn.addEventListener("click", function(event) {
        event.preventDefault();
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        });
    }

}
