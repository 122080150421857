/**
 * Google Tag Manager functionality
 *
 * Any vars that need immediately passing to the data layer on page load
 * should be passed from PHP via the hooked biro_gtm_gtm4wp_compile_datalayer()
 * function in gtm.php.
 */


/**
 * Safe wrapper function for pushing to the GTM dataLayer
 *
 * @param	object variables Variables
 *
 * @return void
 */
function biro_gtm_data_layer_push( variables ) {

	// If the dataLayer exists
	if ( typeof dataLayer != 'undefined' ) {

		// Push the variables to the dataLayer
		dataLayer.push( variables );

	}
}

/**
 * Get a variable from the GTM dataLayer
 *
 * @param	string variableName Variable name
 *
 * @return	mixed
 */
function biro_gtm_data_layer_get( variableName ) {

	// Default the value to null
	var val = null;

	// If the dataLayer exists
	if ( typeof dataLayer != 'undefined' ) {

		// Set val to the variable in the dataLayer
		val = dataLayer[ variableName ];

	}

	// Return the value
	return val;

}

/**
 * Non-AJAX Gravity Forms confirmation event for GTM
 */
// Run when DOM is ready
window.addEventListener( 'DOMContentLoaded', function( event ) {

	// Try to get a confirmation wrapper
	var confirmationWrapper = document.querySelector( '.gform_confirmation_wrapper' );

	// If there's a confirmation wrapper
	if ( confirmationWrapper ) {

		// Push the event to the data layer
		biro_gtm_data_layer_push( {
			event: 'gform_confirmation_loaded',
			formID: 'gform_' + confirmationWrapper.getAttribute( 'id' ).split( '_' ).pop(),
		} );

	}
} );

/**
 * AJAX Gravity Forms confirmation event for GTM
 */
// If jQuery is available
if ( typeof jQuery === 'function' ) {

	// Bind a function to the jQuery document for gform_confirmation_loaded
	jQuery( document ).bind( 'gform_confirmation_loaded', function ( event, formID ) {

			// Push the event to the data layer
			biro_gtm_data_layer_push( {
				event: 'gform_confirmation_loaded',
				formID: 'gform_' + formID,
			} );

		}
	);
}

/* This seems to fire on AJAX forms even when there's no error - back to tracking success for now
// Gravity Forms post render event, to catch validation errors
jQuery( document ).bind( 'gform_post_render', function( event, formID ) {
	var $f = $( '#gform_' + formID );
	if ( $f.find( '.validation_error' ).length ) {
		biro_gtm_data_layer_push({
			'event':	'gform_error',
			'formID':	'gform_' + formID
		});
	}
});
*/


const inViewport = (elem) => {
    let allElements = document.querySelectorAll(elem);
    let windowHeight = window.innerHeight;
    const elems = () => {
        for (let i = 0; i < allElements.length; i++) { 
            let viewportOffset = allElements[i].getBoundingClientRect();  
            let top = viewportOffset.top;  
            if(top < windowHeight){  
                allElements[i].classList.add('is-visible'); 
            } else{
                allElements[i].classList.remove('is-visible');
            }
        }
    }
    elems();
    window.addEventListener('scroll', elems);
}
inViewport('.js-inview'); 

