
//Open Modal
function getVideoUrl(d){
    const videoModal 	= 	document.getElementById("video-image__modal");
   	const videoPlayer 	= 	document.getElementById("video-image__iframe");

    videoPlayer.src 	= 	d.getAttribute("data-video");
    videoModal.classList.add('is-visible');
}


//Close Modal
const videoModal 	= document.getElementById("video-image__modal");
const videoPlayer 	= document.getElementById("video-image__iframe");
const close 		= document.querySelector("#video-image__close");

if (close) {
  close.addEventListener("click", function(event) {
    event.preventDefault();
    videoPlayer.removeAttribute('src')
    videoModal.classList.remove("is-visible");

  });
}