// Try to get accordions on the page
const accordions = document.querySelectorAll( '#content .block-name-accordion' );

// If there are accordions
if ( accordions.length ) {

  // Loop through the accordions
  accordions.forEach( accordion => {

    // Add an event listener for a click on the summary element
    accordion.querySelector( 'summary' ).addEventListener( 'click', event => {

      // If the GTM dataLayer exists
      if ( typeof dataLayer != 'undefined' ) {

        // Send an event
        dataLayer.push( {
          'event': 'accordionToggle',
          'accordionSectionAction': accordion.hasAttribute( 'open' ) ? 'open' : 'close',
          'accordionName': event.currentTarget.textContent,
        } );

      }

    } );

  } );

}
